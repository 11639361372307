<template>
	<form
		method="post"
		action="https://secure.logmeinrescue.com/Customer/Download.aspx"
		@submit="$event.stopPropagation()"
	>
		<TnHeading
			v-if="content.heading"
			type="h2"
			size="2xl"
			class="margin-bottom-l"
			center
			>{{ content.heading }}
		</TnHeading>

		<TnTextField v-bind="textInput">{{ textInput.label }}</TnTextField>
		<input
			type="hidden"
			name="EntryID"
			value="122493"
		/>

		<TnButton
			class="margin-top-2xl"
			type="submit"
			>{{ content.buttonText }}</TnButton
		>
	</form>
</template>

<script>
import GizmoComponentMixin from "../../../../mixins/platform/GizmoComponentMixin.js";

export default defineNuxtComponent({
	name: "RemoteHelp",
	mixins: [GizmoComponentMixin],

	computed: {
		textInput() {
			const textfield = this.content.textfield;
			return {
				error: textfield && textfield.systemMessage,
				label: textfield && textfield.textfieldLabel,
				placeholder: textfield && textfield.textfieldPlaceholder,
				name: textfield && textfield.textfieldName,
			};
		},
	},
});
</script>
